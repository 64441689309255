
export default function useFeatureFlags(){
    const checkFeatures = async function(feature){
        try{
            const result = await $fetch('/.netlify/functions/check-features/', {
                method: 'POST',
                body: {
                    'feature' : feature
                }
            });
            return true;
        }catch(e){
            return false;
        }
    }

    return {
        checkFeatures
    }
}